import { useEffect, useState } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTicketHistory = ({ ticketId }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchTicketHistory = async () => {
      try {
        const { data } = await api.get(`/tickets/${ticketId}/history`);
        setHistory(data);
      } catch (error) {
        toastError(error);
      }
    };

    fetchTicketHistory();
  }, [ticketId]);

  return { history };
}

export default useTicketHistory;