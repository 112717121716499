import axios from "axios";
import { getBackendUrl } from "../config";

const api = axios.create({
  baseURL: getBackendUrl(),
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

export default api;

export const introspect = async ({ token }) => {
  const { data } = await api.post("/auth/introspect", {
    token,
  });

  if (data.valid) {
    return true;
  }

  return false;
};
