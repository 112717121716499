import api from "../../../services/api";

import "./report.css";

const printContent = async (user) => {
  const { data: tickets } = await api.get("/ticketsAll")

  const msgsPromises = tickets.map(async (t) => {
    const { data } = await api.get(`/messages/${t.id}`).then(res => res);

    return data
  });

  const msgs = await Promise.all(msgsPromises);

  let totalMsgs = 0

  msgs.forEach(msg => totalMsgs += msg.count)

  const msgsAverage = totalMsgs / tickets.length

  const totalOpen = tickets.filter(t => t.status === "open").length
  const totalPending = tickets.filter(t => t.status === "pending").length
  const totalClosed = tickets.filter(t => t.status === "closed").length
  const totalPaused = tickets.filter(t => t.status === "paused").length

  const historysPromises = tickets.map(async (t) => {
    const { data } = await api.get(`/tickets/${t.id}/history`).then(res => res);

    return data
  });

  const historys = await Promise.all(historysPromises);

  const historyTreat = (status, allHistorys) => {
    const filter = allHistorys.map(history => {
      return history.filter(h => h.status === status).shift()
    })

    let totalHours = 0

    filter.forEach(h => {
      totalHours += h.hours
    })

    const average = totalHours / filter.length

    return average
  }

  const startOfDay = (date) => new Date(date.setHours(0, 0, 0, 0));

  // Função auxiliar para obter o início da semana (considerando domingo como primeiro dia)
  const startOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 0); // Ajuste para a semana começar no domingo
    return new Date(date.setDate(diff)).setHours(0, 0, 0, 0);
  };

  // Função auxiliar para obter o início do mês
  const startOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

  const now = new Date();
  const todayStart = startOfDay(new Date());
  const weekStart = startOfWeek(new Date());
  const monthStart = startOfMonth(new Date());

  const ticketsToday = tickets.filter(ticket => {
    const createdAt = new Date(ticket.createdAt);
    return createdAt >= todayStart && createdAt < now;
  });

  const ticketsThisWeek = tickets.filter(ticket => {
    const createdAt = new Date(ticket.createdAt);
    return createdAt >= weekStart && createdAt < now;
  });

  const ticketsThisMonth = tickets.filter(ticket => {
    const createdAt = new Date(ticket.createdAt);
    return createdAt >= monthStart && createdAt < now;
  });

  return `
        <!DOCTYPE html>
        <html>
        <head>
            <link rel="stylesheet" type="text/css" href="./report.css">
        </head>
        <body>
            <div class="titleContainer">
                <h1 class="reportTitle" >Relatório de todas as áreas</h1>
                <img class="logo" />
            </div>

            <div class="page">
                <p class="generalInfo">Tickets Atendidos hoje: ${ticketsToday.length}</p>
                <p class="generalInfo">Tickets Atendidos essa semana: ${ticketsThisWeek.length}</p>
                <p class="generalInfo">Tickets Atendidos esse mês: ${ticketsThisMonth.length}</p>
                <p class="generalInfo">Total de Tickets Atendidos: ${tickets.length}</p>

                <p class="generalInfo">Tickets em espera: ${totalPending}</p>
                <p class="generalInfo">Tickets em aberto: ${totalOpen}</p>
                <p class="generalInfo">Tickets pausados: ${totalPaused}</p>
                <p class="generalInfo">Tickets Finalizados: ${totalClosed}</p>

                <p class="generalInfo">Total de mensagens Trocadas: ${totalMsgs}</p>
                <p class="generalInfo">Média de mensagens trocadas por ticket: ${msgsAverage}</p>

                <p class="generalInfo">Tempo médio de atendimento: ${historyTreat("open", historys).toFixed(2)} horas</p>
                <p class="generalInfo">Tempo médio de espera: ${historyTreat("pending", historys).toFixed(2)} horas</p>
            </div>

            <div class="footer">
                <p>${user.name}</p>

                <p>${new Date().toLocaleString()}</p>
            </div>
        </body>
        </html>
      `};

export default printContent