import api from "../../../services/api";
import "./report.css";

const printContent = async (ticket, user) => {
  const { data: msgs } = await api.get(`/messages/${ticket.id}`)

  return `
        <!DOCTYPE html>
        <html>
        <head>
            <link rel="stylesheet" type="text/css" href="./report.css">
        </head>
        <body>
            <div class="titleContainer">
                <h1 class="reportTitle">Relatório do Ticket #${ticket.id} - <small> nome: ${ticket.contact.name}</small></h1></h1>
                <img class="logo" />
            </div>

            <div class="page">
                <p class="generalInfo">Total de mensagem trocadas: ${msgs.count}</p>
                <p class="generalInfo">Status: ${ticket.status}</p>
                
            </div>

            <div class="footer">
                <p>${user.name}</p>

                <p>${new Date().toLocaleString()}</p>
            </div>
        </body>
        </html>
      `}

export default printContent