import Button from '@material-ui/core/Button';
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { SocketContext } from "../../context/SocketProvider";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(8, 8, 3),
  },

  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    gap: 10
  },

  settingOption: {
    marginLeft: "auto",
  },

  margin: {
    margin: theme.spacing(1),
  },

  wordsContainer: {
    width: "100%",
    display: "grid",
    gap: 10,
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  wordsPaper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
}));

const Settings = () => {
  const classes = useStyles();

  const { socket } = useContext(SocketContext);

  const [settings, setSettings] = useState([]);
  const [words, setWords] = useState([]);
  const [sock, setSock] = useState(null);
  const [newWord, setNewWord] = useState("");
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    const fetchSession = async () => {
      try {
        const { data } = await api.get("/settings");
        const { data: bWords } = await api.get("/words");
        if (isMounted.current) {
          setSettings(data);
          setWords(bWords);
        }
      } catch (err) {
        if (isMounted.current) {
          toastError(err);
        }
      }
    };
    fetchSession();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const connect = async () => {
      try {
        const resultSocket = await socket();
        if (isMounted.current) {
          setSock(resultSocket);
        }
      } catch (e) {
        if (isMounted.current) {
          console.error(e);
        }
      }
    };

    connect();
  }, [socket]);

  useEffect(() => {
    if (sock === null) {
      return;
    }

    // sock.on("settings", data => {
    //   if (data.action === "update") {
    //     setSettings(prevState => {
    //       const aux = [...prevState];
    //       const settingIndex = aux.findIndex(s => s.key === data.setting.key);
    //       aux[settingIndex].value = data.setting.value;
    //       return aux;
    //     });
    //   }
    // });
  }, [sock]);

  const handleChangeSetting = async e => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, {
        value: selectedValue,
      });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const getSettingValue = key => {
    const { value } = settings.find(s => s.key === key);
    return value;
  };

  const handleBlockedWords = e => {
    setNewWord(e.target.value);
  };

  const handleAddBlockedWord = async () => {
    try {
      const { data } = await api.post("/settings", {
        word: newWord,
      });

      if (isMounted.current) {
        setWords(prevWords => [...prevWords, data]);
        toast.success(i18n.t("settings.success"));
      }
    } catch (err) {
      if (isMounted.current) {
        toastError(err);
      }
    }
  };

  const handleDeleteWord = async (word) => {
    try {
      await api.delete(`/words`, { data: { word } });

      if (isMounted.current) {
        setWords(prevWords => prevWords.filter(w => w.word !== word));
        toast.success(i18n.t("settings.success"));
      }
    } catch (err) {
      toastError(err);
      return
    }


  };

  return (
    <div className={classes.root}>
      <Container
        className={classes.container}
        maxWidth="sm"
      >
        <Typography
          variant="body2"
          gutterBottom
        >
          {i18n.t("settings.title")}
        </Typography>
        <Paper className={classes.paper}>
          <TextField
            id="locker-words"
            label="Palavras restritas"
            margin="dense"
            variant="outlined"
            fullWidth
            onChange={handleBlockedWords}
          />

          {newWord !== "" && <Button variant="contained" color="primary" onClick={handleAddBlockedWord}>
            Adicionar
          </Button>}
        </Paper>
      </Container>

      <Grid container spacing={3}>
        {
          words.map((word, index) => (
            <Grid item key={index}>
              <Paper className={classes.wordsPaper}>
                <TextField
                  id={`blocked-word-${index}`}
                  margin="dense"
                  variant="outlined"
                  value={word.word}
                  disabled
                />
                <Button variant="contained" color="secondary" onClick={() => handleDeleteWord(word.word)}>
                  Excluir
                </Button>
              </Paper>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

export default Settings;
