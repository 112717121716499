import { Button, Dialog, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import React from 'react';

import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function ImportContactsModal({ open, onClose, data, onSave }) {
  const classes = useStyles();
  const isMounted = React.useRef(true);

  const handleClose = () => {
    onClose();
  };

  const handleSaveContactsFile = () => {
    onSave(data);
  }

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">
          Por favor Confira os dados abaixo
        </DialogTitle>

        <DialogContent dividers>
          <div style={{ display: "flex", gap: 50, padding: 5 }}>
            <Typography variant="subtitle1" gutterBottom>
              Contatos encontrados: {data ? data.length : 0}
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Erros encontrados:
            </Typography>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Número</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data ? data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.number}</TableCell>
                </TableRow>
              )) : <></>}
            </TableBody>
          </Table>

          <Button
            onClick={handleSaveContactsFile}
            style={{ alignSelf: 'end', marginTop: 20 }}
            variant="outlined"
            color="primary">Importar Contatos
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ImportContactsModal;