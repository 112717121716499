import React, { createContext, useState } from "react";
import { io } from "socket.io-client";
import { getBackendUrl } from "../../config";
import { introspect } from "../../services/api";

const SocketContext = createContext();
let isConnecting = false;
let retries = 0;

const SocketProvider = ({ children }) => {
  const [sock, setSocket] = useState(null);

  const socket = async () => {
    if (isConnecting) {
      return Promise.reject();
    }

    if (sock) {
      isConnecting = false;

      if (!sock.connected) {
        isConnecting = true;

        sock.connect();
        isConnecting = false;
      }

      return Promise.resolve(sock);
    }

    return new Promise((resolve, reject) => {
      const token = localStorage.getItem("token");
      introspect({ token })
        .then((isValid) => {
          if (!isValid) {
            console.log("sock: token inválido");
            return reject();
          }

          let newSock = io(getBackendUrl(), {
            transports: ["websocket", "polling", "flashsocket"],
            query: {
              token: token,
            },
          });

          let didntConnectTimeout = setTimeout(() => {
            console.log("socket desconectado por timeout");
            reject();
          }, 15000);

          isConnecting = true;
          newSock.on("connect", () => {
            clearTimeout(didntConnectTimeout);

            newSock.on("error", (e) => {
              console.log("socketProvider: erro no socket", e);
              newSock.disconnect();
              setSocket(null);
            });

            setSocket(newSock);
            resolve(newSock);

            isConnecting = false;
          });

          newSock.on("disconnect", (e) => {
            console.log("socketProvider: socket desconectado por ondisconnect");

            if (retries >= 3) {
              return;
            }
            newSock.connect();
            retries++;
          });
        })
        .catch((e) => {
          console.log("socketProvider Erro ao introspectar o token", e);
        });
    });
  };

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketProvider };
