import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";

import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import { SocketContext } from "../../context/SocketProvider";
import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_TICKETS":
      const newTickets = action.payload;

      newTickets.forEach((ticket) => {
        const ticketIndex = state.findIndex((t) => t.id === ticket.id);
        if (ticketIndex !== -1) {
          state[ticketIndex] = ticket;
          if (ticket.unreadMessages > 0) {
            state.unshift(state.splice(ticketIndex, 1)[0]);
          }
        } else {
          state.push(ticket);
        }
      });

      return [...state];

    case "RESET_UNREAD":
      const ticketId = action.payload;

      const ticketIndex = state.findIndex((t) => t.id === ticketId);
      if (ticketIndex !== -1) {
        state[ticketIndex].unreadMessages = 0;
      }

      return [...state];

    case "UPDATE_TICKET":
      const ticket = action.payload;

      // Remover o ticket da lista original
      const filteredState = state.filter((t) => t.id !== ticket.id);

      // Adicionar o ticket atualizado à lista, dependendo do status
      if (ticket.status === "open" || ticket.status === "pending") {
        filteredState.unshift(ticket);
      }

      return [...filteredState];

    case "UPDATE_TICKET_UNREAD_MESSAGES":
      const ticketUnread = action.payload;

      const ticketIndexUnread = state.findIndex((t) => t.id === ticketUnread.id);
      if (ticketIndexUnread !== -1) {
        state[ticketIndexUnread] = ticketUnread;
        state.unshift(state.splice(ticketIndexUnread, 1)[0]);
      } else {
        state.unshift(ticketUnread);
      }

      return [...state];

    case "UPDATE_TICKET_CONTACT":
      const contact = action.payload;
      const ticketIndexContact = state.findIndex((t) => t.contactId === contact.id);
      if (ticketIndexContact !== -1) {
        state[ticketIndexContact].contact = contact;
      }
      return [...state];

    case "DELETE_TICKET":
      const ticketIdDelete = action.payload;
      return state.filter((t) => t.id !== ticketIdDelete);

    case "RESET":
      return [];

    default:
      return state;
  }
};


const TicketsList = (props) => {
  const { status, searchParam, showAll, selectedQueueIds, updateCount, style, onTicketStatusChange, ticketStatusChanged } =
    props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);
  const [sock, setSock] = useState(null);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, selectedQueueIds]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    if (!status && !searchParam) return;
    dispatch({
      type: "LOAD_TICKETS",
      payload: tickets,
    });
  }, [tickets, searchParam, status]);

  useEffect(() => {
    const connect = async () => {
      try {
        const resultSocket = await socket();
        setSock(resultSocket);
      } catch (e) {
        console.error(e);
      }
    };

    connect();
  }, [socket]);

  useEffect(() => {
    if (sock === null) {
      return;
    }

    const shouldUpdateTicket = (ticket) =>
      !searchParam &&
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    if (status) {
      sock.emit("joinTickets", status);
    } else {
      sock.emit("joinNotification");
    }

    sock.on("ticket", (data) => {
      if (isMounted.current) {
        if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
          dispatch({
            type: "UPDATE_TICKET",
            payload: data.ticket,
          });
          onTicketStatusChange();
        }

        if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
          dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
          onTicketStatusChange();
        }

        if (data.action === "delete") {
          dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
          onTicketStatusChange();
        }
      }
    });

    sock.on("appMessage", (data) => {
      if (isMounted.current) {
        if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
          dispatch({
            type: "UPDATE_TICKET_UNREAD_MESSAGES",
            payload: data.ticket,
          });
          dispatch({
            type: "UPDATE_TICKET_CONTACT",
            payload: data.contact,
          });
        }
      }
    });

    sock.on("contact", (data) => {
      if (isMounted.current) {
        if (data.action === "update") {
          dispatch({
            type: "UPDATE_TICKET_CONTACT",
            payload: data.contact,
          });
        }
      }
    });
  }, [sock, status, searchParam, showAll, user, selectedQueueIds]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      const ticketsFilter = ticketsList.filter(t => t.status === status)

      updateCount(ticketsFilter.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      e.currentTarget.scrollTop = scrollTop - 100;
      loadMore();
    }
  };

  return (
    <Paper className={classes.ticketsListWrapper} style={style}>
      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        <List style={{ paddingTop: 0 }}>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
            <>
              {ticketsList.map((ticket) => {
                return status === ticket.status && <TicketListItem ticket={ticket} key={ticket.id} />;
              })}
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
    </Paper>
  );
};

export default TicketsList;
