import api from "../../../services/api";
import "./report.css";


const printContent = async (user) => {
  const queuesPromises = user.queues.map(async (q) => {
    try {
      const { data } = await api.get(`/tickets/${q.id}/queue`).then(res => res);
      return data;
    } catch (err) {
      return [];
    }
  });

  const tickets = await Promise.all(queuesPromises);

  const msgsPromises = tickets.map(async q => {
    const ticketMsgsPromises = q.map(async (t) => {
      const { data } = await api.get(`/messages/${t.ticket.id}`).then(res => res);
      return data;
    });

    return Promise.all(ticketMsgsPromises);
  });

  const msgs = await Promise.all(msgsPromises);

  let totalMsgs = 0;

  msgs.flat().forEach(msg => totalMsgs += msg.count);

  const msgsAverage = totalMsgs / tickets.flat().length;

  const totalOpen = tickets.flat(1).filter(t => t.ticket.status === "open").length
  const totalPending = tickets.flat(1).filter(t => t.ticket.status === "pending").length
  const totalClosed = tickets.flat(1).filter(t => t.ticket.status === "closed").length
  const totalPaused = tickets.flat(1).filter(t => t.ticket.status === "paused").length

  const historysPromises = tickets.flat(1).map(async (t) => {
    const { data } = await api.get(`/tickets/${t.ticket.id}/history`).then(res => res);

    return data
  });

  const historys = await Promise.all(historysPromises);

  const historyTreat = (status, allHistorys) => {
    const filter = allHistorys.map(history => {
      return history.filter(h => h.status === status).shift()
    })

    let totalHours = 0

    filter.forEach(h => {
      totalHours += h.hours
    })


    const average = totalHours / filter.length

    return average
  }

  const startOfDay = (date) => new Date(date.setHours(0, 0, 0, 0));

  const startOfWeek = (date) => {
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 0); // Ajuste para a semana começar no domingo
    return new Date(date.setDate(diff)).setHours(0, 0, 0, 0);
  };

  const startOfMonth = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

  const now = new Date();
  const todayStart = startOfDay(new Date());
  const weekStart = startOfWeek(new Date());
  const monthStart = startOfMonth(new Date());

  const ticketsToday = tickets.flat(1).filter(ticket => {
    const createdAt = new Date(ticket.ticket.createdAt);
    return createdAt >= todayStart && createdAt < now;
  });

  const ticketsThisWeek = tickets.flat(1).filter(ticket => {
    const createdAt = new Date(ticket.ticket.createdAt);
    return createdAt >= weekStart && createdAt < now;
  });

  const ticketsThisMonth = tickets.flat(1).filter(ticket => {
    const createdAt = new Date(ticket.ticket.createdAt);
    return createdAt >= monthStart && createdAt < now;
  });

  return `
        <!DOCTYPE html>
        <html>
        <head>
            <link rel="stylesheet" type="text/css" href="./report.css">
        </head>
        <body>
            <div class="titleContainer">
                <h1 class="reportTitle" >Relatório de todas as áreas</h1>
                <img class="logo" />
            </div>

            <div class="page">
                <p class="generalInfo">Tickets Atendidos hoje: ${ticketsToday.length}</p>
                <p class="generalInfo">Tickets Atendidos essa semana: ${ticketsThisWeek.length}</p>
                <p class="generalInfo">Tickets Atendidos esse mês: ${ticketsThisMonth.length}</p>
                <p class="generalInfo">Total de Tickets Atendidos: ${tickets.flat(1).length}</p>

                <p class="generalInfo">Tickets em espera: ${totalPending}</p>
                <p class="generalInfo">Tickets em aberto: ${totalOpen}</p>
                <p class="generalInfo">Tickets pausados: ${totalPaused}</p>
                <p class="generalInfo">Tickets Finalizados: ${totalClosed}</p>

                <p class="generalInfo">Total de mensagens Trocadas: ${totalMsgs}</p>
                <p class="generalInfo">Média de mensagens trocadas por ticket: ${msgsAverage}</p>

                <p class="generalInfo">Tempo médio de atendimento: ${historyTreat("open", historys).toFixed(2)} horas</p>
                <p class="generalInfo">Tempo médio de espera: ${historyTreat("pending", historys).toFixed(2)} horas</p>
            </div>

            <div class="footer">
                <p>${user.name}</p>

                <p>${new Date().toLocaleString()}</p>
            </div>
        </body>
        </html>
      `};

export default printContent