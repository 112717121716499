import React, { createContext, useContext, useState } from 'react';

// Crie o contexto
const TicketsContext = createContext();

// Provedor do contexto
export const TicketsProvider = ({ children }) => {
  const [tickets, setTickets] = useState([]);
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  return (
    <TicketsContext.Provider value={{ tickets, setTickets, openCount, setOpenCount, pendingCount, setPendingCount }}>
      {children}
    </TicketsContext.Provider>
  );
};

// Hook para usar o contexto
export const useTickets = () => useContext(TicketsContext);
