import { Button } from "@material-ui/core";
import React from 'react';
import * as XLSX from 'xlsx';
import toastError from "../../errors/toastError";
import api from "../../services/api";
import ImportContactsModal from '../ImportContactsModal';

import { useHistory } from "react-router-dom";

function ImportContactsButton() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const isMounted = React.useRef(true);
  const history = useHistory();

  const handleOpenFileModal = (res) => {
    setData(res);
    setModalOpen(true);
  };

  const handleCloseFileModal = () => {
    setData(null)
    setModalOpen(false);
  };

  const handleSaveFile = async (reqData) => {
    try {
      await api.post('/contacts/import', reqData);

      setData(null);
      setModalOpen(false);

      history.go(0);
    } catch (err) {
      toastError(err);
    }

  };

  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      handleOpenFileModal(jsonData)
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        component="label"
      >
        Importar Contato
        <input
          type="file"
          onChange={handleFileUpload}
          hidden
        />

        <ImportContactsModal aria-labelledby="form-dialog-title" open={modalOpen} onClose={handleCloseFileModal} data={data} onSave={handleSaveFile} />
      </Button>
    </>
  );
}

export default ImportContactsButton;